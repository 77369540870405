import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AppleAuth from "../auth/AppleAuth";
import "./Styles.css";

//login

const kinoTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#f8f8f8",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container_forgot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldModified: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  textFieldSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  title: {
    fontSize: "2rem",
    textAlign: "center",
    color: "var(--darkColor)",
    lineHeight: "1.2",
  },
  titleSm: {
    fontSize: "1rem",
    textAlign: "center",
    color: "var(--darkColor)",
    fontWeight: "500",
    width: "85%",
    margin: "auto",
  },
  terms: {
    marginLeft: "-1.5rem",
    alignSelf: "center",
    cursor: "pointer",
  },
  forgot: {
    cursor: "pointer",
    maxWidth: "72%",
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "var(--darkColor)",
    color: "var(--grey)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "var(--darkColor)",
      boxShadow: "none",
    },
  },
  link: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
    color: "#032836",
  },
  formError: {
    fontSize: "14px",
    color: "red",
    "&::before": {
      content: "⚠ ",
    },
  },
  sign: {
    color: "var(--darkColor)",
    fontSize: "1rem",
    fontWeight: 400,
  },
  checkbox: {
    display: "flex",
    height: "30px",
  },
  start: {
    backgroundColor: "var(--darkColor)",
    border: "1px solid var(--grey)",
    color: "var(--grey)",
    minWidth: "20ch",
  },
  mainError: {
    textAlign: "center",
    color: "red",
    fontWeight: "400",
  },
  alternateAuth: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    gap: "2rem",
  },
  authSeparator: {
    textAlign: "center",
    fontSize: "16px",
    margin: "1.5rem 8px",
    borderBottom: "1px solid #808080",
    lineHeight: "0rem",
  },
  authSeparatorText: {
    backgroundColor: "#fff",
    padding: "0 1rem",
  },
}));

const Login = ({ ...rest }) => {
  const [remember_me, setRemember_me] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorForm, setErrorForm] = useState("");
  const [errorFormNotVerified, setErrorFormNotVerified] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signInDisable, setSignInDisable] = useState(false);
  const [sendCodeEnabled, setSendCodeEnabled] = useState(true);

  const { register, errors, handleSubmit } = useForm({});
  let timer = null;
  const { email, password } = formData;

  const timeZone = moment().utcOffset();
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  const classes = useStyles();

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleLoginClose = () => {
    rest.setModalOpen(false);
    setShowPassword(false);
  };

  const handleRegisterOpen = () => {
    rest.setRegisterOpen(true);
  };

  const switchLoginRegister = () => {
    handleLoginClose();
    handleRegisterOpen();
  };

  const handleChangeCheck = () => {
    setRemember_me(!remember_me);
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorForm("");
  };

  const onSubmit = async (e) => {
    // e.preventDefault();
    setSignInDisable(true);
    setTimeout(() => {
      setSignInDisable(false);
    }, 10000);

    axios
      .post(
        "/login",
        {
          email: email,
          password: password,
          _token: token,
          utcOffset: timeZone,
        },
        config
      )
      .then(
        (response) => {
          if (response.data.error === undefined) {
            //success
            clearTimeout(timer);
            window.location.href = "/profile";
            // if (
            //   rest.redirectAnchor === undefined ||
            //   rest.redirectAnchor == null
            // ) {
            //   window.location.replace(response.data.redirect);
            // } else {
            //   handleLoginClose();
            //   rest.setRedirectAnchor("");
            //   window.location.replace(`/${rest.redirectAnchor}`);
            // }
          } else {
            //something went wrong
            clearTimeout(timer);
            setSignInDisable(false);
            if (response.data.error === "Email verification is required") {
              setErrorForm("");
              setErrorFormNotVerified(`Email verification is required,`);
            } else {
              setErrorFormNotVerified("");
              setErrorForm(response.data.error);
            }
          }
        },
        (error) => {
          console.log("error data: ", error.response.data);
          clearTimeout(timer);
          setSignInDisable(false);
        }
      );
  };

  const handleVerificationResend = () => {
    setSendCodeEnabled(false);
    setTimeout(() => setSendCodeEnabled(true), 60000);
    axios
      .post(
        "/verification-resend",
        {
          email: email,
        },
        config
      )
      .then(
        (response) => {
          if (response.data.message === "Verification email has been sent") {
            setErrorFormNotVerified("");
            setErrorForm("");
            handleLoginClose();
            rest.setVerifyEmail(email);
            rest.setVerifyModalOpen(true);
          }
        },
        (error) => {
          console.log(error.response.data);
          setErrorFormNotVerified("");
        }
      );
  };

  const forgotPassword = () => {
    window.location.href = "/password/reset";
    handleLoginClose();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    rest.modalOpen === false && setErrorForm("") && setErrorFormNotVerified("");
  }, [rest.modalOpen]);

  const props = {
    errorForm,
    setErrorForm,
    handleVerificationResend,
    setFormData,
  };

  return (
    <ThemeProvider theme={kinoTheme}>
      <div className="login">
        <Dialog
          disableBackdropClick
          open={rest.modalOpen}
          onClose={handleLoginClose}
          aria-labelledby="log-in-modal-title"
          fullWidth={true}
          maxWidth={"sm"}
          PaperProps={{
            style: {
              backgroundColor: "#fefefa",
            },
          }}
        >
          <DialogTitle id="log-in-modal-title">
            <p className={classes.title}>Welcome back</p>

            <button className="closeButtonModalKino">
              <CloseIcon
                style={{ width: "50px", color: "gray", cursor: "pointer" }}
                onClick={handleLoginClose}
              />
            </button>
          </DialogTitle>
          <DialogContent>
            {errorForm ? (
              <h4 className={classes.mainError}>
                {"⚠ "}
                {errorForm}
              </h4>
            ) : (
              ""
            )}

            <form onSubmit={(e) => e.preventDefault()} noValidate>
              <div className={classes.container}>
                <TextField
                  autoComplete="on"
                  autoFocus
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </div>
              <div className={classes.container}>
                <div className={classes.textFieldModified}>
                  {errors.email && (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errors.email.message}
                    </p>
                  )}
                  {errorFormNotVerified && sendCodeEnabled ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <h4 className={classes.mainError}>
                          {"⚠ "}
                          {errorFormNotVerified}
                        </h4>
                        <span>&nbsp;</span>
                        <div
                          style={{
                            color: "red",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleVerificationResend}
                        >
                          click here to verify
                        </div>
                      </div>
                
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={classes.container}>
                <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Password is required",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.container}>
                <div className={classes.textField}>
                  {errors.password && (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={classes.container_forgot}>
                <div className="flex row-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={remember_me}
                        onClick={(e) => handleChangeCheck(e)}
                        name="remember-me"
                        id="remember-me"
                        color="primary"
                        className={classes.checkbox}
                        value={remember_me}
                      />
                    }
                  />
                  <label className={classes.terms} htmlFor="remember-me">
                    Remember me
                  </label>
                </div>
                <p className={classes.forgot} onClick={forgotPassword}>
                  Forgot password?
                </p>
              </div>
              <div className={classes.container}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  fullWidth
                  disabled={signInDisable}
                  className={classes.button}
                  type="submit"
                >
                  {" "}
                  {!signInDisable && (
                    <span style={{ fontSize: "1.1rem" }}>Take me in</span>
                  )}
                  {signInDisable && (
                    <div>
                      <CircularProgress
                        style={{
                          height: "17px",
                          width: "17px",
                          marginRight: "5px",
                          color: "#032836",
                          verticalAlign: "middle",
                        }}
                      />
                      &nbsp;Please wait
                    </div>
                  )}
                </Button>
              </div>
            </form>
            <div className={classes.authSeparator}>
              <span className={classes.authSeparatorText}>OR</span>
            </div>
            <div className={classes.alternateAuth}>
              <div
                className="auth-provider google-login"
                onClick={() => {
                  window.location.replace("/signin/google");
                }}
              >
                <svg
                  aria-hidden="true"
                  className="svg-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <g>
                    <path
                      d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
                      fill="#EA4335"
                    ></path>
                  </g>
                </svg>
                {/* Sign in with Google */}
              </div>

              <div
                className="auth-provider facebook-login"
                onClick={() => {
                  window.location.replace("/signin/facebook");
                }}
              >
                <svg
                  width="534"
                  height="531"
                  viewBox="0 0 534 531"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M533.667 267C533.667 119.724 414.276 0.333344 267 0.333344C119.724 0.333344 0.333344 119.724 0.333344 267C0.333344 400.101 97.8494 510.422 225.333 530.427V344.083H157.625V267H225.333V208.25C225.333 141.417 265.145 104.5 326.057 104.5C355.233 104.5 385.75 109.708 385.75 109.708V175.333H352.124C318.997 175.333 308.667 195.889 308.667 216.978V267H382.625L370.802 344.083H308.667V530.427C436.151 510.422 533.667 400.101 533.667 267"
                    fill="#1877F2"
                  />
                </svg>
                {/* Sign in with Facebook */}
              </div>

              <AppleAuth
                {...rest}
                {...props}
                // buttonName={'Sign in with Apple'}
                method="signin"
              />
            </div>
            <div className={classes.container}>
              <p className={classes.link}>
                Not a member?{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#032836",
                  }}
                  href={"/signup"}
                >
                  SIGN UP
                </a>
              </p>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Login;
