import React from 'react';
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './Terms.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: '#ededed',
    marginTop: '1rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  details: {
    backgroundColor: '#f8f8f8',
  },
  background: {
    
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <div className="terms-container flex column_center">
      <Helmet>
        <title>Kino Live | Terms & Conditions</title>
      </Helmet>
      <h1>Terms & Conditions</h1>
      <p>Last updated: 03/16/2022</p>
      <p>
        <b>
          BY CLICKING/CHECKING THE "I AGREE" BUTTON/BOX, ACCESSING A KINETX CO
          SITE OR BY UTILIZING THE KINO SERVICES YOU AGREE TO BE BOUND BY THESE
          TERMS OF SERVICE AND ALL EXHIBITS, ORDER FORMS, AND INCORPORATED
          POLICIES (THE “TERMS” OR “TERMS OF SERVICE”). THE KINO SERVICES ARE
          NOT AVAILABLE TO PERSONS WHO ARE NOT LEGALLY ELIGIBLE TO BE BOUND BY
          THESE TERMS OF SERVICE.
        </b>
      </p>
      <div className={classes.root}>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>1. Introduction</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Welcome to <b>Kinetx Co LLC</b> (“<b>Company</b>”, “<b>we</b>”,
                “<b>our</b>”, “<b>us</b>”)! As you have just clicked our Terms
                of Service, please pause, grab a cup of coffee and carefully
                read the following pages. It will take you approximately
                20 minutes.
              </p>
              <p className="text-pad">
                These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>
                ”) govern your use of our web pages located at{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.kino.live"
                >
                  https://www.kino.live
                </a>
                , our desktop application Kino Live, and our mobile application
                Kino Live (together or individually “<b>Service</b>”) operated
                by Kinetx Co LLC.
              </p>
              <p className="text-pad">
                Our Privacy Policy also governs your use of our Service and
                explains how we collect, safeguard and disclose information that
                results from your use of our web pages. Please read it here{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://kino.live/privacy"
                >
                  https://kino.live/privacy
                </a>
                .
              </p>
              <p className="text-pad">
                Your agreement with us includes these Terms and our Privacy
                Policy (“<b>Agreements</b>”). You acknowledge that you have read
                and understood Agreements, and agree to be bound of them.
              </p>
              <p className="text-pad">
                If you do not agree with (or cannot comply with) Agreements,
                then you may not use the Service, but please let us know by
                emailing at <a href="mailto:team@kino.live">team@kino.live</a>{" "}
                so we can try to find a solution. These Terms apply to all
                visitors, users and others who wish to access or use Service.
              </p>
              <p className="text-pad">Thank you for being responsible.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              2. Communications
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                By creating an Account on our Service, you agree to subscribe to
                newsletters, marketing or promotional materials and other
                information we may send. However, you may opt out of receiving
                any, or all, of these communications from us by following the
                unsubscribe link or by emailing at{" "}
                <a href="mailto:team@kino.live">
                  <b>team@kino.live</b>
                </a>
                .
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>3. Purchases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                If you wish to purchase any product or service made available
                through Service (“<b>Purchase</b>”), you may be asked to supply
                certain information relevant to your Purchase including, without
                limitation, your credit card number, the expiration date of your
                credit card, your billing address, and your shipping
                information.
              </p>
              <p className="text-pad">
                You represent and warrant that:
                <ul>
                  <li>
                    you have the legal right to use any credit card(s) or other
                    payment method(s) in connection with any Purchase;
                  </li>
                  <li>
                    the information you supply to us is true, correct and
                    complete
                  </li>
                </ul>
              </p>
              <p className="text-pad">
                We may employ the use of third party services for the purpose of
                facilitating payment and the completion of Purchases. By
                submitting your information, you grant us the right to provide
                the information to these third parties subject to our Privacy
                Policy.
              </p>
              <p className="text-pad">
                We reserve the right to refuse or cancel your order at any time
                for reasons including but not limited to: product or service
                availability, errors in the description or price of the product
                or service, error in your order or other reasons.
              </p>
              <p className="text-pad">
                We reserve the right to refuse or cancel your order if fraud or
                an unauthorized or illegal transaction is suspected.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>
              4. Contests, Sweepstakes and Promotions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Any contests, sweepstakes or other promotions (collectively, “
                <b>Promotions</b>”) made available through Service may be
                governed by rules that are separate from these Terms of Service.
                If you participate in any Promotions, please review the
                applicable rules as well as our Privacy Policy. If the rules for
                a Promotion conflict with these Terms of Service, Promotion
                rules will apply.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className={classes.heading}>
              5. Subscriptions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Some parts of Service are billed on a subscription basis (“
                <b>Subscription(s)</b>”). You will be billed in advance on a
                recurring and periodic basis (“<b>Billing Cycle</b>”). Billing
                cycles are set either on a monthly or annual basis, depending on
                the type of subscription plan you select when purchasing a
                Subscription.
              </p>
              <p className="text-pad">
                At the end of each Billing Cycle, your Subscription will
                automatically renew under the exact same conditions unless you
                cancel it or Kinetx Co LLC cancels it. You may cancel your
                Subscription renewal either through your online account
                management page or by contacting Kinetx Co LLC customer support
                team.
              </p>
              <p className="text-pad">
                A valid payment method, including credit card or PayPal, is
                required to process the payment for your subscription. You shall
                provide Kinetx Co LLC with accurate and complete billing
                information including full name, address, state, zip code,
                telephone number, and a valid payment method information. By
                submitting such payment information, you automatically authorize
                Kinetx Co LLC to charge all Subscription fees incurred through
                your account to any such payment instruments.
              </p>
              <p className="text-pad">
                Should automatic billing fail to occur for any reason, Kinetx Co
                LLC will issue an electronic invoice indicating that you must
                proceed manually, within a certain deadline date, with the full
                payment corresponding to the billing period as indicated on the
                invoice.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography className={classes.heading}>6. Free Trial</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Kinetx Co LLC may, at its sole discretion, offer a Subscription
                with a free trial for a limited period of time (“Free Trial”).
              </p>
              <p className="text-pad">
                You may be required to enter your billing information in order
                to sign up for Free Trial.
              </p>
              <p className="text-pad">
                If you do enter your billing information when signing up for
                Free Trial, you will not be charged by Kinetx Co LLC until Free
                Trial has expired. On the last day of Free Trial period, unless
                you cancelled your Subscription, you will be automatically
                charged the applicable Subscription fees for the type of
                Subscription you have selected.
              </p>
              <p className="text-pad">
                {/* At any time and without notice, Kino Live LLC reserves
								the right to:
								<ul>
									<li>modify Terms of Service of Free Trial offer.</li>
									<li>cancel such Free Trial offer.</li>
								</ul> */}
                At any time and without notice, Kinetx Co LLC reserves the right
                to (i) modify Terms of Service of Free Trial offer, or (ii)
                cancel such Free Trial offer.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography className={classes.heading}>7. Fee Changes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Kinetx Co LLC, in its sole discretion and at any time, may
                modify Subscription fees for the Subscriptions. Any Subscription
                fee change will become effective at the end of the then-current
                Billing Cycle.
              </p>
              <p className="text-pad">
                Kinetx Co LLC will provide you with a reasonable prior notice of
                any change in Subscription fees to give you an opportunity to
                terminate your Subscription before such change becomes
                effective.
              </p>
              <p className="text-pad">
                Your continued use of Service after Subscription fee change
                comes into effect constitutes your agreement to pay the modified
                Subscription fee amount.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography className={classes.heading}>8. Refunds</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Except when required by law, paid Subscription fees are
                non-refundable.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography className={classes.heading}>9. Content</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Our Service allows you to post, link, store, share and otherwise
                make available certain information, text, graphics, videos, or
                other material (“<b>Content</b>”). You are responsible for
                Content that you post on or through Service, including its
                legality, reliability, and appropriateness.
              </p>
              <p className="text-pad">
                By posting Content on or through Service, You represent and
                warrant that: (i) Content is yours (you own it) and/or you have
                the right to use it and the right to grant us the rights and
                license as provided in these Terms, and (ii) that the posting of
                your Content on or through Service does not violate the privacy
                rights, publicity rights, copyrights, contract rights or any
                other rights of any person or entity. We reserve the right to
                terminate the account of anyone found to be infringing on a
                copyright.
              </p>
              <p className="text-pad">
                You retain any and all of your rights to any Content you submit,
                post or display on or through Service and you are responsible
                for protecting those rights. We take no responsibility and
                assume no liability for Content you or any third party posts on
                or through Service. However, by posting Content using Service
                you grant us the right and license to use, modify, publicly
                perform, publicly display, reproduce, and distribute such
                Content on and through Service. You agree that this license
                includes the right for us to make your Content available to
                other users of Service, who may also use your Content subject to
                these Terms.
              </p>
              <p className="text-pad">
                Kinetx Co LLC  has the right but not the obligation to monitor
                and edit all Content provided by users.
              </p>
              <p className="text-pad">
                In addition, Content found on or through this Service are the
                property of Kinetx Co LLC or used with permission. You may not
                distribute, modify, transmit, reuse, download, repost, copy, or
                use said Content, whether in whole or in part, for commercial
                purposes or for personal gain, without express advance written
                permission from us.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <Typography className={classes.heading}>
              10. Prohibited Uses
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                You may use Service only for lawful purposes and in accordance
                with Terms. You agree not to use Service:
              </p>
              <p className="text-pad">
                <ul className="list-cap">
                  <li>
                    In any way that violates any applicable national or
                    international law or regulation.{" "}
                  </li>
                  <li>
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </li>
                  <li>
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </li>
                  <li>
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                  </li>
                  <li>
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.{" "}
                  </li>
                  <li>
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.{" "}
                  </li>
                </ul>
              </p>
              <p className="text-pad">Additionally, you agree not to:</p>
              <p className="text-pad">
                <ul className="list-cap">
                  <li>
                    Use Service in any manner that could disable, overburden,
                    damage, or impair Service or interfere with any other
                    party’s use of Service, including their ability to engage in
                    real time activities through Service.
                  </li>
                  <li>
                    Use any robot, spider, or other automatic device, process,
                    or means to access Service for any purpose, including
                    monitoring or copying any of the material on Service.
                  </li>
                  <li>
                    Use any manual process to monitor or copy any of the
                    material on Service or for any other unauthorized purpose
                    without our prior written consent.
                  </li>
                  <li>
                    Use any device, software, or routine that interferes with
                    the proper working of Service.
                  </li>
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material which is malicious or technologically
                    harmful.
                  </li>
                  <li>
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of Service, the server on which
                    Service is stored, or any server, computer, or database
                    connected to Service.
                  </li>
                  <li>
                    Attack Service via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </li>
                  <li>
                    Take any action that may damage or falsify Company rating.
                  </li>
                  <li>
                    Otherwise attempt to interfere with the proper working of
                    Service.
                  </li>
                </ul>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header"
          >
            <Typography className={classes.heading}>11. Analytics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may use third-party Service Providers to monitor and analyze
                the use of our Service, that may include and are not limited to
                the following:
              </p>
              <p className="text-pad">
                <h3>Google Analytics </h3>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.
              </p>
              <p className="text-pad">
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy?hl=en"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <p className="text-pad">
                We also encourage you to review the Google's policy for
                safeguarding your data:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.google.com/analytics/answer/6004245"
                >
                  https://support.google.com/analytics/answer/6004245
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Firebase</h3>
                Firebase is analytics service provided by Google Inc.
              </p>
              <p className="text-pad">
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy?hl=en"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <p className="text-pad">
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy?hl=en"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Fathom Analytics</h3>
                Fathom Analytics is analytics service provided by Conva Ventures
                Inc. You can find their Privacy Policy here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://usefathom.com/privacy/"
                >
                  https://usefathom.com/privacy/
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Piwik / Matomo</h3>
                Piwik or Matomo is a web analytics service. You can visit their
                Privacy Policy page here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://matomo.org/privacy-policy"
                >
                  https://matomo.org/privacy-policy
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Clicky</h3>
                Clicky is a web analytics service. Read the Privacy Policy for
                Clicky here: 
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://clicky.com/terms"
                >
                  https://clicky.com/terms
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Cloudflare analytics </h3>
                Cloudflare analytics is a web analytics service operated by
                Cloudflare Inc. Read the Privacy Policy here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cloudflare.com/privacypolicy/"
                >
                  https://www.cloudflare.com/privacypolicy/
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Statcounter</h3>
                Statcounter is a web traffic analysis tool. You can read the
                Privacy Policy for Statcounter here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://statcounter.com/about/legal/"
                >
                  https://statcounter.com/about/legal/
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Flurry Analytics</h3>
                Flurry Analytics service is provided by Yahoo! Inc.
              </p>
              <p className="text-pad">
                You can opt-out from Flurry Analytics service to prevent Flurry
                Analytics from using and sharing your information by visiting
                the Flurry's Opt-out page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dev.flurry.com/secure/optOut.do"
                >
                  https://dev.flurry.com/secure/optOut.do
                </a>
                .
              </p>
              <p className="text-pad">
                For more information on the privacy practices and policies of
                Yahoo!, please visit their Privacy Policy page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://legal.yahoo.com/us/en/yahoo/privacy/index.html"
                >
                  https://legal.yahoo.com/us/en/yahoo/privacy/index.html
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Mixpanel</h3>
                Mixpanel is provided by Mixpanel Inc.
              </p>
              <p className="text-pad">
                You can prevent Mixpanel from using your information for
                analytics purposes by opting-out. To opt-out of Mixpanel
                service, please visit this page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mixpanel.com/optout/"
                >
                  https://mixpanel.com/optout/
                </a>
                .
              </p>
              <p className="text-pad">
                For more information on what type of information Mixpanel
                collects, please visit the Terms of Use page of Mixpanel:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mixpanel.com/terms/"
                >
                  https://mixpanel.com/terms/
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Unity Analytics</h3>
                Unity Analytics is provided by Unity Technologies.
              </p>
              <p className="text-pad">
                For more information on what type of information Unity Analytics
                collects, please visit their Privacy Policy page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://unity3d.com/legal/privacy-policy"
                >
                  https://unity3d.com/legal/privacy-policy
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Azure DevOps</h3>
                Azure DevOps is a Software as a service (SaaS) platform from
                Microsoft that provides an end-to-end DevOps toolchain for
                developing and deploying software.
              </p>
              <p className="text-pad">
                You can find Microsoft Privacy Statement here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://privacy.microsoft.com/en-gb/privacystatement"
                >
                  https://privacy.microsoft.com/en-gb/privacystatement
                </a>
                .
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
          >
            <Typography className={classes.heading}>
              12. No Use By Minors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Service is intended only for access and use by individuals at
                least eighteen (18) years old. By accessing or using any of
                Company, you warrant and represent that you are at least
                eighteen (18) years of age and with the full authority, right,
                and capacity to enter into this agreement and abide by all of
                the terms and conditions of Terms. If you are not at least
                eighteen (18) years old, you are prohibited from both the access
                and usage of Service.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13a-content"
            id="panel13a-header"
          >
            <Typography className={classes.heading}>13. Accounts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                When you create an account with us, you guarantee that you are
                above the age of 18, and that the information you provide us is
                accurate, complete, and current at all times. Inaccurate,
                incomplete, or obsolete information may result in the immediate
                termination of your account on Service.
              </p>
              <p className="text-pad">
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>
              <p className="text-pad">
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than you, without appropriate authorization. You
                may not use as a username any name that is offensive, vulgar or
                obscene.
              </p>
              <p className="text-pad">
                We reserve the right to refuse service, terminate accounts,
                remove or edit content, or cancel orders in our sole discretion.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14a-content"
            id="panel14a-header"
          >
            <Typography className={classes.heading}>
              14. Intellectual Property
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Service and its original content (excluding Content provided by
                users), features and functionality are and will remain the
                exclusive property of Kinetx Co LLC and its licensors. Service
                is protected by copyright, trademark, and other laws of the
                United States and foreign countries. Our trademarks and trade
                dress may not be used in connection with any product or service
                without the prior written consent of Kinetx Co LLC.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15a-content"
            id="panel15a-header"
          >
            <Typography className={classes.heading}>15. Copyright</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We respect the intellectual property rights of others. It is our
                policy to respond to any claim that Content posted on Service
                infringes on the copyright or other intellectual property rights
                (“<b>Infringement</b>”) of any person or entity.
              </p>
              <p className="text-pad">
                If you are a copyright owner, or authorized on behalf of one,
                and you believe that the copyrighted work has been copied in a
                way that constitutes copyright infringement, please submit your
                claim via email to {" "}
                <a href="mailto:team@kino.live">team@kino.live</a>, with the
                subject line: “Copyright Infringement” and include in your claim
                a detailed description of the alleged Infringement as detailed
                below, under “DMCA Notice and Procedure for Copyright
                Infringement Claims”
              </p>
              <p className="text-pad">
                You may be held accountable for damages (including costs and
                attorneys' fees) for misrepresentation or bad-faith claims on
                the infringement of any Content found on and/or through Service
                on your copyright.
              </p>
              <p className="text-pad">
                The Service exists as a platform for users to communicate with
                one another and share content such as audio, video, text, or
                images. We are not liable for any user content shared during use
                of the Service, including content that may infringe on
                copywrite.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel16a-content"
            id="panel16a-header"
          >
            <Typography className={classes.heading}>
              16. DMCA Notice and Procedure for Copyright Infringement Claims
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                You may submit a notification pursuant to the Digital Millennium
                Copyright Act (DMCA) by providing our Copyright Agent with the
                following information in writing (see 17 U.S.C 512(c)(3) for
                further detail):
              </p>
              <p className="text-pad">
                <ul className="list-cap">
                  <li>
                    an electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright's interest;
                  </li>
                  <li>
                    a description of the copyrighted work that you claim has
                    been infringed, including the URL (i.e., web page address)
                    of the location where the copyrighted work exists or a copy
                    of the copyrighted work;
                  </li>
                  <li>
                    identification of the URL or other specific location on
                    Service where the material that you claim is infringing is
                    located;{" "}
                  </li>
                  <li>your address, telephone number, and email address; </li>
                  <li>
                    a statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law;{" "}
                  </li>
                  <li>
                    a statement by you, made under penalty of perjury, that the
                    above information in your notice is accurate and that you
                    are the copyright owner or authorized to act on the
                    copyright owner's behalf.{" "}
                  </li>
                </ul>
              </p>
              <p className="text-pad">
                You can contact our Copyright Agent via email at  
                <a href="mailto:team@kino.live">team@kino.live</a>
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel17a-content"
            id="panel17a-header"
          >
            <Typography className={classes.heading}>
              17. Error Reporting and Feedback
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                You may provide us either directly at{" "}
                <a href="mailto:team@kino.live">team@kino.live</a> or via third
                party sites and tools with information and feedback concerning
                errors, suggestions for improvements, ideas, problems,
                complaints, and other matters related to our Service (“
                <b>Feedback</b>”). You acknowledge and agree that:
                <ul>
                  <li>
                    you shall not retain, acquire or assert any intellectual
                    property right or other right, title or interest in or to
                    the Feedback;
                  </li>
                  <li>
                    Company may have development ideas similar to the Feedback;
                  </li>
                  <li>
                    Feedback does not contain confidential information or
                    proprietary information from you or any third party;
                  </li>
                  <li>
                    Company is not under any obligation of confidentiality with
                    respect to the Feedback. In the event the transfer of the
                    ownership to the Feedback is not possible due to applicable
                    mandatory laws, you grant Company and its affiliates an
                    exclusive, transferable, irrevocable, free-of-charge,
                    sublicensable, unlimited and perpetual right to use
                    (including copy, modify, create derivative works, publish,
                    distribute and commercialize) Feedback in any manner and for
                    any purpose.
                  </li>
                </ul>
              </p>
              <p className="text-pad">
                The third party sites and tools mentioned above include the
                following:{" "}
              </p>
              <p className="text-pad">
                <h3>Bugsnag</h3>
                Bugsnag is a platform for monitoring and logging stability of
                applications provided by Bugsnag Inc. Please read their Privacy
                Policy here:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.bugsnag.com/legal/privacy-policy/"
                >
                  https://docs.bugsnag.com/legal/privacy-policy/
                </a>
                .
              </p>
              <p className="text-pad">
                <h3>Firebase Crashlytics</h3>
                Firebase Crashlytics is bug reporting service provided by Google
                Inc.
              </p>
              <p className="text-pad">
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy?hl=en"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <p className="text-pad">
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy?hl=en"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel18a-content"
            id="panel18a-header"
          >
            <Typography className={classes.heading}>
              18. Links To Other Web Sites or Services
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by Kinetx Co LLC.
              </p>
              <p className="text-pad">
                Kinetx Co LLC has no control over, and assumes no responsibility
                for the content, privacy policies, or practices of any third
                party web sites or services. We do not warrant the offerings of
                any of these entities/individuals, their websites, or their
                services.
              </p>
              <p className="text-pad">
                YOU ACKNOWLEDGE AND AGREE THAT KINETX CO LLC SHALL NOT BE
                RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
                LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE
                OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE
                ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
              </p>
              <p className="text-pad">
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
                POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU
                VISIT.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel19a-content"
            id="panel19a-header"
          >
            <Typography className={classes.heading}>
              19. Event Waiver and Release of Liability
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Kinetx Co LLC may use the Service to host online events (herein
                referred to as the “Events”). Events may be open to the public
                or may be subject to registration/invitation. By using the
                Service to participate in the Events, you agree that you are
                voluntarily participating in the Events and are participating in
                the Events at your own risk. You are aware of any risks
                associated with participation in the Events, which may include
                but are not limited to physical or psychological injury, pain,
                suffering, illness, disfigurement, temporary or permanent
                disability (including paralysis), economic or emotional loss,
                and death. You understand that these injuries or outcomes may
                arise from your own or others’ negligence. Nonetheless, you
                assume all related risks, both known and unknown to you, of your
                participation in the Events. You further agree to indemnify,
                defend, and hold us and our officers, directors, employees, and
                agents harmless against any and all claims, suits, or actions of
                any kind whatsoever for liability, damages, compensation, or
                otherwise, brought by you or anyone on your behalf, including
                attorney’s fees and any related costs.
              </p>
              <p className="text-pad">
                Kinetx Co LLC may charge admission fees for participating in the
                Events. Once paid, admission fees may not be refunded for any
                reason whatsoever, including but not limited to event
                cancellation, event host availability, technical issues, and any
                foreseen or unforeseen causes for missing the Events.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel20a-content"
            id="panel20a-header"
          >
            <Typography className={classes.heading}>
              20. Disclaimer Of Warranty
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
                OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
                SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
                THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES,
                THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
                YOUR SOLE RISK.
              </p>
              <p className="text-pad">
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
                WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR
                ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
                SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES
                OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR
                NEEDS OR EXPECTATIONS.
              </p>
              <p className="text-pad">
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p className="text-pad">
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21a-content"
            id="panel21a-header"
          >
            <Typography className={classes.heading}>
              21. Limitation Of Liability
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
                DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
                PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER
                IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND
                EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
                APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR
                OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH
                THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR
                PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT
                AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS,
                STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
                PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
                PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
                COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS
                AND/OR SERVICES, IN THE TWELVE (12) MONTHS PRECEDING SUCH
                INCIDENT, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL
                OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
                THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel22a-content"
            id="panel22a-header"
          >
            <Typography className={classes.heading}>22. Termination</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may terminate or suspend your account and bar access to
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of Terms.
              </p>
              <p className="text-pad">
                You are able to terminate your Kino account and thereby cancel
                your Kino subscription through your profile options when logging
                into the Kino Live website. Annual subscriptions may be
                cancelled within 30 days of starting the annual subscription.
                Cancellations after the first 30 days of an annual subscription
                will not result in a refund. Monthly subscriptions may be
                cancelled at any time. If you cancel a monthly subscription, you
                will still be charged for the entire billing period in which you
                cancel. You will still be able to use the Service until said
                billing period ends. <br /> All provisions of Terms which by
                their nature should survive termination shall survive
                termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity and limitations of
                liability.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel23a-content"
            id="panel23a-header"
          >
            <Typography className={classes.heading}>
              23. Governing Law
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                These Terms shall be governed and construed in accordance with
                the laws of State of New York without regard to its conflict of
                law provisions.
              </p>
              <p className="text-pad">
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service and supersede and replace any prior
                agreements we might have had between us regarding Service.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel24a-content"
            id="panel24a-header"
          >
            <Typography className={classes.heading}>
              24. Trade Sanctions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Use of the Service may be affected by U.S. trade sanction laws.
                By using the Service, you represent and warrant that: (i) that
                you are not a citizen of or located within a country or
                territory that is subject to a U.S. trade sanction, (ii) you
                will not use the Service in any countries or territories subject
                to a U.S. trade sanction, and (iii) you are not listed on any
                U.S. Government list that prohibits you from legally using the
                Service.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel25a-content"
            id="panel25a-header"
          >
            <Typography className={classes.heading}>
              25. Changes To Service
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We reserve the right to withdraw or amend our Service, and any
                service or material we provide via Service, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of Service is unavailable at any time or
                for any period. From time to time, we may restrict access to
                some parts of Service, or the entire Service, to users,
                including registered users.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel26a-content"
            id="panel26a-header"
          >
            <Typography className={classes.heading}>
              26. Amendments To Terms
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                We may amend Terms at any time by posting the amended terms on
                the Kino Live website. It is your responsibility to review
                these Terms periodically.
              </p>
              <p className="text-pad">
                Your continued use of the Platform following the posting of
                revised Terms means that you accept and agree to the changes.
                You are expected to check this page frequently so you are aware
                of any changes, as they are binding on you.
              </p>
              <p className="text-pad">
                By continuing to access or use our Service after any revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, you are no longer authorized
                to use Service.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel27a-content"
            id="panel27a-header"
          >
            <Typography className={classes.heading}>
              27. Waiver And Severability
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                No waiver by Company of any term or condition set forth in Terms
                shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition, and any
                failure of Company to assert a right or provision under Terms
                shall not constitute a waiver of such right or provision.
              </p>
              <p className="text-pad">
                If any provision of Terms is held by a court or other tribunal
                of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel28a-content"
            id="panel28a-header"
          >
            <Typography className={classes.heading}>28. Events</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Kinetx Co LLC may use the Service to host online events (herein
                referred to as the “Events”). Events may be open to the public
                or may be subject to registration/invitation. By using the
                Service to participate in the Events, you agree that you are
                voluntarily participating in the Events and are participating in
                the Events at your own risk. You are aware of any risks
                associated with participation in the Events, which may include
                but are not limited to physical or psychological injury, pain,
                suffering, illness, disfigurement, temporary or permanent
                disability (including paralysis), economic or emotional loss,
                and death. You understand that these injuries or outcomes may
                arise from your own or others’ negligence. Nonetheless, you
                assume all related risks, both known and unknown to you, of your
                participation in the Events. You further agree to indemnify,
                defend, and hold us and our officers, directors, employees, and
                agents harmless against any and all claims, suits, or actions of
                any kind whatsoever for liability, damages, compensation, or
                otherwise, brought by you or anyone on your behalf, including
                attorney’s fees and any related costs.
              </p>
              <p className="text-pad">
                Kinetx Co LLC may charge admission fees for participating in the
                Events. Once paid, admission fees may not be refunded for any
                reason whatsoever, including but not limited to event
                cancellation, event host availability, technical issues, and any
                foreseen or unforeseen causes for missing the Events.
              </p>
              <p className="text-pad">
                Video call sessions that occur during the Events may be recorded
                by the event host(s). By using the Service to participate in the
                Events, you acknowledge and agree to be recorded during the
                Events and grant to Kinetx Co LLC and any other persons involved
                in the Events a perpetual, irrevocable, royalty-free, fully
                paid-up, worldwide license to use your image, voice, and name in
                connection with the recording of the Events or modified versions
                thereof. Muting your microphone or turning off your camera
                during the Events does not absolve your agreement to these
                terms.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel29a-content"
            id="panel29a-header"
          >
            <Typography className={classes.heading}>
              29. Acknowledgement
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE
                TO BE BOUND BY THEM.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.details}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel30a-content"
            id="panel30a-header"
          >
            <Typography className={classes.heading}>30. Contact Us</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="text-pad">
                Please send your feedback, comments, requests for technical
                support:
              </p>
              <p className="text-pad">
                By email: <a href="mailto:team@kino.live">team@kino.live</a>.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Terms;
