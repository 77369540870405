import React from 'react';

const PaymentCancel = () => {
    return (
        <div>
            {/* Your component content goes here */}
        </div>
    );
};

export default PaymentCancel;