import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorImage from "../../assets/images/ErrorImages/Error404.webp";
import backgroundImage from "../../assets/images/SignInUP/background.png";
import "./Error.css";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundSize: 'cover',
		minHeight: "60vh",
		display: "flex",
		alignItems: "center",
		//margin: "60px auto",
		marginTop: "4%",
		[theme.breakpoints.down("sm")]: {
			margin: "50px auto",
		},
	},
	gridContainer: {
		justifyContent: "center",
		margin: "60px auto"
	},
	divContent: {
		display: "flex",
		justifyContent: "center",
		flexFlow: "column",
		alignItems: "center",
	},
	firstThirdText: {
		fontWeight: '400',
		fontSize: '20px',
		lineHeight: '23px',
		color: '#333333',
	},
	secondText: {
		fontWeight: '600',
		fontSize: '72px',
		lineHeight: '84px',
		color: '#032836',
	},
	button: {
		border: '2px solid #032836',
		padding: "8px 15px",
		borderRadius: "4px",
		fontWeight: '700',
		fontSize: '16px',
		lineHeight: '19px',
		textAlign: 'center',
		letterSpacing: '0.005em',
		textTransform: 'uppercase',
		color: '#032836',
	},
	image: {
		[theme.breakpoints.down("xl")]: {
			width: "unset",
		},
		[theme.breakpoints.down("lg")]: {
			width: "90%",
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			width: "95%",
		},
	},
	link: {
		textDecoration: "none",
		margin: "45px 0 60px",
		[theme.breakpoints.down("xs")]: {
			margin: "35px 0 55px",
		},
	},
}));

const Error404 = () => {
	const classes = useStyles();
	return (
		<>
			<Container maxWidth="Xl" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={12} sm={12}>
						<div className={classes.divContent}>
							<Typography variant="h6" className={classes.firstThirdText}>
								Oops...
							</Typography>
							<Typography variant="h2" className={classes.secondText}>
								404
							</Typography>
							<Typography variant="h6" className={classes.firstThirdText}>
								Page not found
							</Typography>
							<Link to="/" className={classes.link}>
								<Button className={classes.button}>Return to Homepage</Button>
							</Link>
							<img
								src={ErrorImage}
								className={classes.image}
								alt="Error 404"
							/>
						</div>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Error404;
