import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, makeStyles, Grid, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    formError: {
        fontSize: "14px",
        color: "red",
        "&::before": {
          content: "⚠ ",
        },
    },

    generalError: {
        backgroundColor: "#f8d7da",
        borderRadius: "6px",
        padding: "4px",
        fontSize: "15px",
        color: "red",
        "&::before": {
            content: "⚠ ",
          },
    },
    bookedError: {
        backgroundColor: "#F293221A",
        borderRadius: "6px",
        padding: "5px",
        fontSize: "14px",
        fontWeight: "600",
        color: "#032836",
        "&::before": {
            content: "⚠ ",
          },
    },
    successMessage: {
        backgroundColor: "#d8f8d7",
        borderRadius: "6px",
        padding: "4px",
        fontSize: "15px",
        color: "green"
    },
    kinoButtonStyle: {
        textAlign: "center",
        fontSize: "18px",
     
        backgroundColor: "var(--darkColor)",
        color: "var(--grey)",
        boxShadow: "0px 1px 5px -1px white",
        margin: "0rem auto",
        textTransform: "inherit",
        borderRadius: "10px ",
        fontWeight: "500 ",
        "&:hover": {
       
        backgroundColor: "var(--darkerColor) ",
        },
        "&:disabled": {
         
            color: "var(--grey)",
            opacity: 0.5,
        },
        display: "flex",
        margin: "15px",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 10px",
    },
    secondaryButton:{
        color: "var(--darkColor)",
        fontSize: "18px",
    }
}));
const EventSignupModal = ({open,setOpen}) => {
    const handleClose = (event,reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const [errors, setErrors] = useState({});
    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmail] = useState("");
    const [industry,setIndustry] = useState("");
    const [eventId,setEventId] = useState("");
    const [loading, setLoading] = useState(false);
    const { handleSubmit} = useForm({});
    const [solidOut,setSolidOut] = useState(false);
    const [submited,setSubmited] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);
    const appUrl = process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_KINO_URL;
    //get the event id from back-end
    const style = useStyles();
    useEffect(() => {
        getEventId();

    },[]);

    const getEventId = async ()=>{
        try {
            const response = await axios.get(`${appUrl}/event/id`);
            setEventId(response.data?.id);
            setSolidOut(response.data?.sold_out);
        } catch (error) {
            console.log("error in get event id",error);
            return null
        }
    }

    const validateForm = () => {
        const errors = {};
        if (!firstName) {
            errors.first_name = "First name is required";
        }
        if (!lastName) {
            errors.last_name = "Last name is required";
        }
        if (!email) {
            errors.email = "Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
            errors.email = "Invalid email format";
        }
        if (!industry) {
            errors.industry = "Industry is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }
    const postRegisterToEvent = async () => {
        setErrors({});
        if (!validateForm()) {return;}
        setLoading(true);
        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            industry: industry,
            event_id: eventId
        };
        console.log("Form data",data);
        axios.post(`${appUrl}/event/signup`, data).then((response) => {
            const resData= response.data
            // console.log("got evet signup response",response);
            if(resData?.payment_url){
                window.location.href = resData.payment_url;
            }
            if(resData?.subscribed){
                setSuccessMessage(resData.message);
            }
            setSubmited(true);
        }).catch((err) => {
            console.log("error in event signup",err?.response?.data);
            if(err.response?.data){
                setErrors(err.response.data);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <div>
            <Dialog open={open} onClose={handleClose}
            PaperProps={{style:{boxShadow:"0 0 12px rgba(255,255,255,1)"}}}
            >
                <DialogTitle style={{textAlign:"center"}}>
                    <span style={{fontSize:"40px"}}>AI Workshop</span>
                    <p style={{fontSize:"16px",fontWeight:"normal"}}>Fill the form to register to the workshop.</p>
                </DialogTitle>

                <form action="#" onSubmit={handleSubmit(postRegisterToEvent)}>
                <DialogContent>
                    <div className={`signup-steps`}>
                        <div className='line'></div>
                        <div className={`step step-1 active`}>
                            <span>Registration</span>
                        </div>
                        <div className={`step step-2`}>
                            <span>Payment</span>
                        </div>
                        <div className={`step step-3`}>
                            <span>Confirmation</span>
                        </div>
                    </div>
                    {(solidOut && !submited) && (
                            <p className={`${style.bookedError} mt-20 text-center`}>
                            {"🙌 "}
                            We're fully booked! Register below for the next slot.
                            </p>
                         )} 
                    {/* <p style={{fontWeight:"bold",fontsize:"20px",textAlign:"center"}}>Attendee Information.</p>
                    <p style={{textAlign:"center"}}>Fill in the form below to register for the event.</p> */}
                    <div style={{marginTop: "20px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    label="First name"
                                    type={"text"}
                                    variant="outlined"
                                    onInput={(e) => setFirstName(e.target.value)}
                                    />
                                    {errors.first_name && (
                                        <p className={style.formError}>
                                        {"⚠ "}
                                        {errors.first_name}
                                        </p>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    label="Last name"
                                    type={"text"}
                                    variant="outlined"
                                    onInput={(e) => setLastName(e.target.value)}
                                    />
                                    {errors.last_name && (
                                        <p className={style.formError}>
                                        {"⚠ "}
                                        {errors.last_name}
                                        </p>
                                    )}
                            </Grid>
                            
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    name="email"
                                    fullWidth
                                    label="Email"
                                    type={"email"}
                                    variant="outlined"
                                    onInput={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email && (
                                        <p className={style.formError}>
                                        {"⚠ "}
                                        {errors.email}
                                        </p>
                                    )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    name="industry"
                                    fullWidth
                                    label="Industry"
                                    type={"text"}
                                    variant="outlined"
                                    onInput={(e) => setIndustry(e.target.value)}
                                    />
                                    {errors.industry && (
                                        <p className={style.formError}>
                                        {"⚠ "}
                                        {errors.industry}
                                        </p>
                                    )}
                            </Grid>
                        </Grid>
                        <p className={`font-sm mt-20 text-center ls-75`}>
                                By registering this event you accept Kino's{" "}
                                <a href="/terms" className={`text-blue`}>Terms & Conditions</a> and{" "}
                                <a href="/privacy" className={`text-blue`}>Privacy Policy</a>.
                        </p>
                        {errors.message && (
                            <p className={`${style.generalError} mt-20 text-center`}>
                            {"⚠ "}
                            {errors.message}
                            </p>
                        )}

                        {successMessage && (
                            <p className={`${style.successMessage} mt-20 text-center`}>
                            {"✔ "}
                            {successMessage}
                            </p>
                        )}
                        
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor: "#F8FAFC"}}>
                    <Button type={"button"} onClick={handleClose} className={style.secondaryButton}>
                        {submited ? 'CLOSE' : 'CANCEL'}
                    </Button>
                    <Button type={"submit"} 
                    className={style.kinoButtonStyle} 
                    color="primary"
                    disabled={loading || submited}
                    >
                        { loading ? null : solidOut ? `REGISTER` : `NEXT: PAYMENT`}
                        {loading && (
                      <div>
                        <CircularProgress
                          style={{
                            height: "17px",
                            marginLeft: "5px",
                            width: "17px",
                            marginRight: "0px",
                            marginTop: "-1px",
                            color: "#ffffff",
                            verticalAlign: "middle",
                            textAlign: "center"
                          }}
                        />
                      </div>
                    )}
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default EventSignupModal;