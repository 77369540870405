const ScrollToView = (btn) =>{
    setTimeout(() => {
        if(btn === "hero" || btn === "top"){
            window.scrollTo(0, 0);
            return;
        }
        const element = document.getElementById(btn);
        console.log('clicked', element);
        if(element){
            element.scrollIntoView({behavior: "smooth", inline: "nearest"});
        }
    }, 400)
}

export default ScrollToView;