import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles({
  container: {
    padding: '30px 40px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'var(--darkColor)',
    lineHeight: '1.2',
  },
  titleSm: {
    fontSize: '1rem',
    textAlign: 'center',
    color: 'var(--darkColor)',
    fontWeight: '500',
    width: '100%',
    margin: 'auto',
  },
  submit: {
    backgroundColor: '#032836',
    margin: '0 10px',
    '&:hover': {
      transition: '.7s',
      background: '#032836',
      color: '#fff',
    },
  },
  dialogContainer: {
    minHeight: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

const SignUpSuccessful = ({ ...rest }) => {
  const search = window.location.search;
  const status = new URLSearchParams(search).get('status');

  const handleClose = () => {
    rest.openSuccessModal(false);
  };

  const handleErrorMsg = (e) => {
    if (status === 'success') {
      window.history.pushState({}, null, '/');
      return;
    }
  };

  useEffect(() => {
    handleErrorMsg();
  }, [search, rest.userCookie]);

  const classes = useStyles();
  return (
    <Dialog
      disableBackdropClick
      open={
        !rest.isAuth === undefined || !rest.isAuth === 0
          ? rest.successModal
          : false
      }
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>{'Welcome to Kino'}</Typography>
        <button className='closeButtonModalKino' onClick={handleClose}>
          <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fal'
            data-icon='times'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 320 512'
            className='svg-inline--fa fa-times fa-w-10'
          >
            <path
              fill='gray'
              d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19
                  0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34
                  120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12
                  3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12
                  3.12-8.19 0-11.31L193.94 256z'
            ></path>
          </svg>
        </button>
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <Typography variant='subtitle1' className={classes.subtitle}>
          Your sign up was successful. Now you can freely utilize all of our
          features.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          {'Button 1'}
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          {'Button 2'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpSuccessful;
