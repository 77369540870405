import axios from 'axios';

const doAuthApple = (data, method) =>{
    return axios.post('/signin/apple/verifyToken/web',{
        idToken: data.idToken,
        method: method,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email
    })
}

export default doAuthApple
