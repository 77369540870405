import { Button, Card, CardContent, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SpacesImage  from "../../assets/images/AIWorkshop/AIWorkshopBG.svg";
import { ReactComponent as SpacesIcon } from "../../assets/images/AIWorkshop/AIWorkshopSpacesIcon.svg";
import { ReactComponent as SuccessCheckmark} from "../../assets/images/AIWorkshop/SuccessCheckmark.svg";
import kinoLogo from "../../assets/images/AIWorkshop/KinoLogo.svg"
import kinetxLogo from "../../assets/images/AIWorkshop/KinetxLogo.svg"
import bringThemHomeNowLogo from "../../assets/images/AIWorkshop/BringThemHomeNowLogo.svg"

import './AIWorkshopGeneral.css'
import axios from 'axios';
import { Skeleton } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    container: {
        color: "#fff",
        backgroundImage: `url(${SpacesImage})`,
        backgroundSize: "cover",
        position: "relative",
        height: "100vh",
        minHeight: "800px",
        margin: "0 auto",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: { 
            height: "initial", 
            paddingBottom: "6rem" 
        },
      },
      paymentCard: {
        position: "relative",
        width: "calc(100vw - 240px)",
        height: "calc(100vh - 200px)",
        backdropFilter: "blur(3px)",
        minHeight: "650px",
        marginTop: "60px",
        backgroundColor: "rgba(255, 255, 255, 0.1) ",
        [theme.breakpoints.down("sm")]:{
            marginTop: "100px",
            width: "calc(100vw - 20px)",
            height: "auto",
        }
      },
      cardContent:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "200px",
      },
      paymentAmountContainer: {
        display: "flex",
        position: "relative",
        margin: "1rem 0",
        flexDirection: "column",
        alignItems: "center",
        color: "#1a1a1a80",
      },
      kinoButtonStyle: {
        textAlign: "center",
        background: "#f29322",
        color: "var(--grey)",
        padding:"8px 20px",
        boxShadow: "0px 1px 5px -1px white",
        margin: "0rem auto",
        textTransform: "inherit ",
        borderRadius: "8px",
        fontWeight: "600",
        "&:hover": {
          backgroundColor: "#bc7828 ",
        },
        display: "block" ,
        margin: "15px",
    },
    amountText: {
        fontWeight: "600",
        fontSize: "70px",
        color: "#ffffff",
        margin: "20px 0"
    },
    paymentSuccessful: {
        fontSize: "50px",
        [theme.breakpoints.down("sm")]:{
            fontSize: "30px",
        }
    },
    checkIcon: {
        marginLeft: "20px",
        [theme.breakpoints.down("sm")]:{
            width: "150px",
            height: "150px",
        }
    },
    spacesIconTop: {
        width: "500px",
        height: "500px",
        position: "absolute",
        right: "10px",
        zIndex: "0",
        maxWidth: "500px",
        maxHeight: "500px",
        top: "50px",
        opacity: "0.3",
        [theme.breakpoints.down("lg")]: {    
        maxWidth: "500px",
            right: "0%", 
        },
        [theme.breakpoints.down("sm")]:{
            with: "280px",
            height: "280px",
            right: "-100px",
        }
    },
    spacesIconBottom: {
        width: "500px",
        height: "500px",
        position: "absolute",
        left: "-250px",
        zIndex: "0",
        maxWidth: "500px",
        maxHeight: "500px",
        bottom: "-50px",
        opacity: "0.3",
        [theme.breakpoints.down("lg")]: {    
        maxWidth: "500px",
            right: "0%", 
        },
        [theme.breakpoints.down("sm")]:{
            with: "260px",
            height: "260px",
            bottom: "-10px",
        },
    },

    sponsorIcon: {
        height: "80px",
    },
    sponsorMessage: {
        color: "white",
        fontWeight: "500",
        display: "block",
        marginBottom: "1rem",
      },
      sponsorMessage2: {
        color: "white",
        fontWeight: "500",
        display: "none",
        marginBottom: "1rem",
      },
}));
const PaymentSuccess = () => {
    const classes = useStyles();
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    const [session, setSession] = useState(null);
    const appUrl = process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_KINO_URL;
    // console.log("session id",sessionId);
    // Rest of your code goes here
    const getSession = (sessionId) => {
        axios.get(`${appUrl}/event/session/status?session_id=${sessionId}`).then((response) => {
            setSession(response.data);
            if(response.data.invoice?.number === "INVOICE NOT READY"){
                getSession(sessionId)
            }
        }).catch((error) => {
            setSession('error');
        });
    }

    useEffect(() => {
        getSession(sessionId);
    },[]);

    // console.log("session",session);
    return (
        <div className={classes.container}>
            <SpacesIcon className={classes.spacesIconTop}/>
            <SpacesIcon className={classes.spacesIconBottom}/>
            <Card elevation={4} className={classes.paymentCard}>
                <CardContent className={classes.cardContent}>
                    {(session?.invoice) ? <div className={`flex flex-column align-center w-full`}>
                        <div>
                            <SuccessCheckmark className={classes.checkIcon}/>
                        </div>
                        <h2 className={`${classes.paymentSuccessful} text-center text-white text-shadow`}>Payment Successful</h2>
                        <p className={`text-center mt-1 text-white font-20 text-shadow`}>Thank you for your payment. A receipt will be sent to your email with the payment details.</p>
                        <p className={`text-center mt-1 text-white font-20 text-shadow`}>Look for an email with the workshop event details.  </p>
                        
                        <div className={classes.paymentAmountContainer}>
                            <span className='text-gray font-20'>Amount Paid</span>
                            <h1 className={`${classes.amountText} text-shadow`}>${(session.invoice.amount / 100).toFixed(2)}</h1>
                            <span className='text-gray font-20'>Invoice ID: {session.invoice?.number === "INVOICE NOT READY" ? (<>
                                <span>Generating invoice</span>
                                <CircularProgress
                                    style={{
                                    height: "18px",
                                    marginLeft: "5px",
                                    width: "18px",
                                    marginRight: "0px",
                                    marginTop: "-1px",
                                    color: "#ffffff",
                                    verticalAlign: "middle",
                                    textAlign: "center"
                                    }}
                                />
                                </>) : session.invoice?.number}</span>
                        </div>
                        <div className='flex'>
                            <Button href={session.invoice.download} disabled={session.invoice?.number === "INVOICE NOT READY"} target='_blank' className={`${classes.kinoButtonStyle} font-20`} color="primary">
                                DOWNLOAD INVOICE
                            </Button>
                        </div>
                    </div> :
                    <div className={`flex flex-column align-center w-full`}>
                        <Skeleton variant="circular" width={209} height={209} />
                        <Skeleton variant="text" width={250} height={32} />
                        <Skeleton variant="text" width={400} height={38} />
                        <Skeleton variant="text" width={300} height={32} />
                        <Skeleton variant='text' width={165} height={65} />
                        <Skeleton variant='rectangular' width={240} height={40} style={{margin:'15px'}}/>
                    </div>}
                    <div className={`paymentLogoSection flex flex-between w-full align-end`}>
                        <div className={`logoSection1 flex flex-column`}>
                            <span className={classes.sponsorMessage+ ' sponsorMessage text-white'}>Sponsored by:</span>
                            <div>
                                <object type="image/svg+xml" data={kinoLogo} className={`${classes.sponsorIcon}`} aria-label="Kino" />
                                <object type="image/svg+xml" data={kinetxLogo} className={`${classes.sponsorIcon}`} aria-label="Kinetx" />
                            </div>
                        </div>
                        <div className={`logoSection2 mobileLogoSection2 flex`}>
                        <span className={classes.sponsorMessage2 + " sponsorMessage2 text-white"}>In support of:</span>

                            <object type="image/svg+xml" data={bringThemHomeNowLogo} className={`${classes.sponsorIcon}`} aria-label="Bring Them Home Now" />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentSuccess;