import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Community.css';
import { Button, TextField } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  formError: {
    fontSize: '14px !important',
    color: 'red',
    textAlign: '-webkit-left',
    width: '240px',
    padding: '0px !important',
    '&::before': {
      content: '⚠ ',
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#032836',
    },
  },
});

const Community = () => {
  const classes = useStyles();
  const { handleSubmit } = useForm({});

  //field value states
  const [fullName, setFullName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  //error states
  const [fullNameError, setFullNameError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  //boolean states
  const [disableLetsTalk, setDisableLetsTalk] = useState(false);

  //button text states
  const [buttonTextDisabled, setButtonTextDisabled] = useState(
    <span>
      <CircularProgress
        style={{
          height: '17px',
          width: '17px',
          verticalAlign: 'middle',
          color: '#032836',
          marginRight: '5px',
        }}
      />
      &nbsp;Please wait
    </span>
  );

  const handleNumberValidation = (number) => {
    let value = number.replace(/\D/g, '');
    if (value === undefined) {
      setPhoneNumber();
    } else {
      setPhoneNumber(value);
    }
  };

  const handleFullName = (name) => {
    let regex = /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/i;
    if (name.match(regex)) {
    } else if (name === '') {
      setFullNameError('');
    } else {
      setFullNameError('Please enter a valid name');
    }
  };

  const handleEmailAdress = (emailAddress) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailAddress.match(regex)) {
    } else if (emailAddress === '') {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email');
    }
  };

  const resetData = () => {
    setFullName('');
    setBusinessName('');
    setPhoneNumber('');
    setEmail('');
  };

  const sendEmail = () => {
    setDisableLetsTalk(true);
    if (
      !fullName ||
      !businessName ||
      !phoneNumber ||
      !email ||
      fullNameError === 'Please enter a valid name' ||
      emailError === 'Please enter a valid email'
    ) {
      if (fullName == null || fullName === '') {
        setFullNameError('');
        setFullNameError('Full name is required');
      }
      if (businessName == null || businessName === '') {
        setBusinessNameError('');
        setBusinessNameError('Business name is required');
      }
      if (phoneNumber == null || phoneNumber === '') {
        setPhoneError('');
        setPhoneError('Phone number is required');
      }
      if (email == null || email === '') {
        setEmailError('');
        setEmailError('Email is required');
      }
      setDisableLetsTalk(false);
    } else {
      fetch(
        `/newKino/communityMail.php?&email=${email}&business=${businessName}&phone=${phoneNumber}&name=${fullName}`
      ).catch((err) => console.error(err));
      setTimeout(() => {
        resetData();
        setButtonTextDisabled('Thank you');
      }, 1500);
    }
  };

  useEffect(() => {}, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Kino Live | Community</title>
      </Helmet>
      <div className='community flex column-center'>
        <h2>Kino for the Community</h2>
        <div className='community-container flex column-center'>
          <div className='community-container flex row-center'>
            <aside>
              <p>
                Kino Live is a video chat platform that brings friends,
                families, and teams together while providing a more human user
                experience. Our Kino for the community program helps support
                local businesses and strengthen our sense of community in these
                uncertain times through partnership and giving back.
              </p>
              <p>
                Kino is dedicating resources to help overcome the current
                challenges our community is facing. We can all stay safe in the
                virtual space, where "social distancing" doesn't have to feel so
                isolating, and "gatherings" are not a bad word. Kino is designed
                with freedom in mind allowing individuals to form focused groups
                without breaking out into separate rooms.
              </p>
              <p>
                We'd love to connect, learn more, and see if Kino can help your
                business and community.
              </p>

              <p>
                Please fill out the form below, and let's start the
                conversation!
              </p>
            </aside>
            <main>
              <div className='community-main-img'></div>
              <p>Bringing communities and businesses one step closer</p>
            </main>
          </div>
          <div className='community-contact flex column-center'>
            <form className='flex column-center'>
              <div className='community-form flex'>
                <div className='community-form1 flex row-center'>
                  <div className='community-form-item flex'>
                    <TextField
                      id='name'
                      name='name'
                      label='Full Name'
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      value={fullName}
                      onBlur={(e) => {
                        handleFullName(e.currentTarget.value);
                      }}
                      onChange={(e) => {
                        setFullName(e.currentTarget.value);
                      }}
                      onClick={() => {
                        setFullNameError('');
                      }}
                    />
                    <div className='form-error'>
                      {fullNameError ? (
                        <p className={classes.formError}>
                          {'⚠ '}
                          {fullNameError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='community-form-item flex'>
                    <TextField
                      id='business'
                      name='business'
                      label='Business name'
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      value={businessName}
                      type='text'
                      onChange={(e) => {
                        setBusinessName(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        setBusinessNameError('');
                      }}
                    />
                    <div className='form-error'>
                      {businessNameError ? (
                        <p className={classes.formError}>
                          {'⚠ '}
                          {businessNameError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className='community-form2 flex row-center'>
                  <div className='community-form-item flex'>
                    <TextField
                      id='phoneNumber'
                      name='phone'
                      label='Phone number'
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      value={phoneNumber}
                      type='text'
                      onChange={(e) => {
                        setPhoneNumber(e.currentTarget.value);
                        handleNumberValidation(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        setPhoneError('');
                      }}
                    />
                    <div className='form-error'>
                      {phoneError ? (
                        <p className={classes.formError}>
                          {'⚠ '}
                          {phoneError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='community-form-item flex'>
                    <TextField
                      id='email'
                      name='email'
                      label='Email Address'
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      value={email}
                      type='text'
                      onBlur={(e) => {
                        handleEmailAdress(e.currentTarget.value);
                      }}
                      onChange={(e) => {
                        setEmail(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        setEmailError('');
                      }}
                    />
                    <div className='form-error'>
                      {emailError ? (
                        <p className={classes.formError}>
                          {'⚠ '}
                          {emailError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                variant='contained'
                type='submit'
                disabled={disableLetsTalk}
                style={{
                  fontSize: '0.9rem',
                  minWidth: '250px',
                  backgroundColor: '#032836',
                  color: '#ffffff',
                }}
                onClick={handleSubmit(sendEmail)}
              >
                {!disableLetsTalk && "Let's talk"}
                {disableLetsTalk && buttonTextDisabled}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Community;
