import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import SpacesImage from "../../assets/images/AIWorkshop/AIWorkshopBG.svg";
import spacesIcon from "../../assets/images/AIWorkshop/AIWorkshopSpacesIcon.svg";
import chatGPTIcon from "../../assets/images/AIWorkshop/ChatGPTIcon.svg"
import kinoLogo from "../../assets/images/AIWorkshop/KinoLogo.svg"
import kinetxLogo from "../../assets/images/AIWorkshop/KinetxLogo.svg"
import bringThemHomeNowLogo from "../../assets/images/AIWorkshop/BringThemHomeNowLogo.svg"
import EventSignupModal from "./EventSignupModal";

import "./AIWorkshop.css";
import Button from "@material-ui/core/Button";

const heroImg = "";
const imageYourCommute = "";

const useStyles = makeStyles((theme) => ({
  aiWorkshopMainContainer: {
    backgroundImage: `url(${SpacesImage})`,
    backgroundSize: "cover",
    position: "relative",
    margin: "0 auto",
    marginLeft: "auto",
    marginRight: "auto",  
    [theme.breakpoints.down("sm")]: { height: "initial", paddingBottom: "6rem" },
    // [theme.breakpoints.up("lg")]: {paddingBottom: "66%" },
  },
  aiWorkshop: {
    maxWidth: "1650px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  aiWorkshopContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "block",
    [theme.breakpoints.down("sm")]: { textAlign: "center", justifyContent: "center ", margin: "0 " },
  },
  borderClass: {
    background: "#F29322",
    height: "1px",
    width: "75%",
    textAlign: "center",
    margin: ".5rem 0",
    // [theme.breakpoints.between("xs", "sm")]: { width: "80%", margin: "1rem auto", marginTop: "0%", marginBottom: "1rem" },
    // [theme.breakpoints.down("xs")]: { width: "80%", marginBottom: "2rem",  marginTop: "2rem" },
    [theme.breakpoints.down("sm")]: { width: "60%", margin: "1rem auto" },
   
  },
  aiWorkshopTitles: {
    padding: "1rem",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    textAlign: "center",
    
  },
  mainTitleaiWorkshopWhite: {
    fontSize: "2rem",
    color: "#ffff",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    textShadow: "0px 0px 4px #F29322",
    maxWidth: "500px",
    margin: "auto",
    marginTop: "1%",
    // [theme.breakpoints.down("md")]: {fontSize: "2rem" },
  },
  secondTitleaiWorkshopOrange: {
    fontSize: "2rem",
    color: "#F29322",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    textShadow: "0px 0px 4px #F29322",
  },
  descriptionaiWorkshop: {
    fontSize: "2rem",
    maxWidth: "1000px",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    margin: "1rem 0rem",
    color: "white",
    fontWeight: "500",
    textShadow: "0px 0px 3px #FFFFFFB3",
    [theme.breakpoints.between(0,"xs")]: {fontSize: "1.1rem", padding:"0 1rem", margin: "auto" },
  },
  descriptionaiWorkshop2: {
    fontSize: "23px",
    maxWidth: "700px",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    margin: "1rem 0rem",
    color: "white",
    fontWeight: "400",
    textShadow: "0px 0px 3px #FFFFFFB3",
    [theme.breakpoints.down("xs")]: {fontSize: "1.1rem", padding:"0 1rem", margin: "auto" },
  },
  descriptionaiWorkshopRed: {
    fontSize: "23px",
    maxWidth: "430px",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    // marginTop: "2.5rem",
    color: "#ff0000",
    fontWeight: "500",
    textDecorationLine: "underline",
    textShadow: "0px 0px 4px #DC7B7B80",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {fontSize: "1.1rem", padding:"0 1rem" },
  },

  mainTitleSection: {
    fontSize: "20rem",
    height: "100vh",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${SpacesImage})`,
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  mainTitleContent: {
    display: "flex",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  container: {
    marginTop: "3%",
    position: 'relative',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    
  },

  img: {
    width: '37rem',
    zIndex: "1",
    position: "relative",
    [theme.breakpoints.between("md", "lg")]: { marginRight: "8rem"},
    [theme.breakpoints.between("sm", "md")]: { width: "25rem"},

  },

  backgroundImageSize: {
    width: "90%",
    padding: "0 1rem"
  },
  spaceIcon1: {
    width: "600px",
    height: "600px",
    position: "absolute",
    right: "2%",
    zIndex: "0",

    top: "0%",
    opacity: "0.3",
    filter: "drop-shadow(0px 0px 5px #FFFFFF66)",
  },
  spaceIcon2: {
    position: "absolute",
    width: "600px",
    height: "600px",
    left: "-17%",
    bottom: "2%",
    Zindex: "0",
    opacity: "0.3",
    filter: "drop-shadow(0px 0px 5px #FFFFFF66)",
  },
  aiWorkshopContent: {
    display: "flex",
    width: "90%",
    height: "auto",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  CTAButton: {
    display: "block ",
    margin: "0rem auto ",
    marginTop: "1rem ",
    textTransform: "unset",
    textAlign: "center",
    alignContent: "center",
    backgroundColor: "#F29322 !important",
    width: "240px",
    height: "55px",
    fontWeight: "600",
    color: "#fff",
    fontSize: "24px !important",
    marginBottom: "0.5rem ",
    "&:hover": {
      backgroundColor: "rgb(3, 40, 54)",
    },
    boxShadow: "0px 4px 4px #0328364D",
  },
  buttonTextContainer: {
    textAlign: "center",
    position: "relative",
    color: "white",
    fontSize: "20px",
  },
  chatGPTIcon: {
    textAlign: "center",
    filter: "drop-shadow(0px 0px 11px #74AA9C)",
    [theme.breakpoints.down("md")]: {
      width: "300px",
      height: "300px",
    },
  },
  sponsorSection: {
    marginBottom: "1rem",
  },
  logoSection: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    }
    
  },
  logoSection2: {
    display: "block",
    // justifyContent: "flex-end",
    paddingLeft: "28.5%",
    alignContent: "end",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0%",
      maxWidth: "100%",
    }
  },
  sponsorIcon: {
    height: "80px",
  },
  sponsorIcon2: {
    height: "80px",
    borderRadius: "10px",
  },
  sponsorMessage: {
    color: "white",
    fontWeight: "500",
    display: "block",
    marginBottom: "1rem",
    fontSize: "23px",
    position: "absolute",
    bottom: "0",
  },
  sponsorMessageMobile: {
    color: "white",
    fontWeight: "500",
    display: "none",
    marginBottom: "1rem",
    fontSize: "20px",
  },
  sponsorMessage2: {
    color: "white",
    fontWeight: "500",
    display: "none",
    marginBottom: "1rem",
  },
  signupAI: {
    textAlign: "center",
    fontSize: "18px",
  },
  rightGridItem: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  sessionTitle: {
    fontSize: "2rem",
    fontWeight: "400",
    color: "#F29322",
    lineHeight: "1.5",
    letterSpacing: ".55px",
    textShadow: "0px 0px 4px #FFFFFF4D",
  },
  sessionDate: {
    color: "#FFFFFF",
    textShadow: "0px 0px 4px #FFFFFF4D",
    fontSize: "18px",
    textDecorationLine: "none",
    marginLeft: "1rem",
    fontWeight: "300",
  },
  disclaimerAI: {
    color: "white",
    fontSize: "18px",
    marginTop: "1.5rem",
  }
}
));


const AIWorkshop = ({ ...rest }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className={classes.aiWorkshopMainContainer + " aiWorkshopMainContainer"}>
      <object type="image/svg+xml" data={spacesIcon} className={`${classes.spaceIcon1} spaceIcon1`} aria-label="Spaces Icon"/>
      <object type="image/svg+xml" data={spacesIcon} className={`${classes.spaceIcon2} spaceIcon2`} aria-label="Spaces Icon"/>

      <div className={classes.aiWorkshop}>

          <div className={classes.container}>
            <Grid container className={classes.aiWorkshopContainer + " aiWorkshopContainer"} >
            <object type="image/svg+xml" data={chatGPTIcon} className={`${classes.chatGPTIcon} mobileGPTIcon`} aria-label="ChatGPT" />
            <Grid className={classes.aiWorkshopTitles+ " aiWorkshopTitles"}>
                  <h1 className={classes.mainTitleaiWorkshopWhite + " mainTitleaiWorkshopWhite"}>Work Smarter - Not Harder 
                  
                  <span> Discover</span>
                  <span className={classes.secondTitleaiWorkshopOrange + " secondTitleaiWorkshopOrange"}> ChatGPT  </span>
                  &
                  <span className={classes.secondTitleaiWorkshopOrange + " secondTitleaiWorkshopOrange"}> DALL-E 3</span>
                  
                  </h1>
                  <h2 className={classes.sessionDate + " sessionDate"}>Hosted by AI Expert Oded Tertman</h2>
                </Grid>
              <Grid className={classes.aiWorkshopContent + " aiWorkshopContent"}>
                
                <Grid xs={8} className={"mobileGridItem"}>
                  <div>
                    <div className= "mobileTitle">
                      <span className={classes.sessionTitle + " sessionTitle"}>Session 1</span> <span className={classes.sessionDate + " sessionDate"}> May 1st, 2:00 PM - 3:00 ET</span>
                    </div>
                    <p className={classes.descriptionaiWorkshop + " descriptionaiWorkshop"}>Master ChatGPT4: Work easier with specialized AI assistants</p>
                  </div>
                  <Grid className={classes.borderClass + " borderClass"}></Grid>
                  <div>
                    <div  className= "mobileTitle">
                      <span className={classes.sessionTitle + " sessionTitle"}>Session 2</span> <span className={classes.sessionDate + " sessionDate"}> May 2nd, 2:00 PM - 3:00 ET</span>
                    </div>
                      <p className={classes.descriptionaiWorkshop + " descriptionaiWorkshop"}>Master DALL-E 3: Generate captivating business images</p>
                  </div>
                  <Grid className={classes.borderClass + " borderClass"}></Grid>
                  <div>
                    <p className={classes.descriptionaiWorkshop2 + " descriptionaiWorkshop2"}>Explore the advantages AI offers. <br/> Support a cause that makes a difference.</p>

                    
                    
                  </div>
                  <div className={classes.disclaimerAI + " disclaimerAI"}>* ChatGPT 4 subscription required</div>
                  <span className={classes.sponsorMessage+ " sponsorMessage"}>Sponsored by:</span>
                </Grid>
                <Grid xs={4} className={classes.rightGridItem + " mobileGridItem"}>
                <object type="image/svg+xml" data={chatGPTIcon} className={`${classes.chatGPTIcon} chatGPTIcon`} aria-label="ChatGPT" />
                  <div className={classes.buttonTextContainer + " buttonTextContainer"}>
                    
                      <span className="signupAI">
                        Price: $150
                      </span>
                    <Button
                      className={classes.CTAButton+ " CTAButton"}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => setModalOpen(true)}
                      >
                      GET TICKETS
                    </Button>
                    
                      <span className= {classes.descriptionaiWorkshop2 + " descriptionaiWorkshop2"}>Funds from the event will be donated to </span>
                      <br/>
                      <span className= {classes.descriptionaiWorkshopRed + " descriptionaiWorkshop2"}>BRING THEM HOME NOW!</span> 
                    
                  </div>
                </Grid>         
              </Grid>

              <Grid className={classes.sponsorSection + " sponsorSection"}>
                <Grid className={classes.logoSection + " mobileLogoSection"}>
                <Grid xs={5} className={classes.logoSection2 + " mobileLogoSection2 mobileGridItem logoItem"}>
                  {/* <span className={classes.sponsorMessage2 + " sponsorMessage2 supportMessage"}>In support of:</span> */}
                    <object type="image/svg+xml" data={bringThemHomeNowLogo} className={classes.sponsorIcon2 +  " sponsorIcon2"} aria-label="Bring Them Home Now" />
                  </Grid>
                  <Grid xs={5} className={" mobileGridItem logoItem"}>
                  
                  <span className={classes.sponsorMessageMobile + " sponsorMessageMobile"}>Sponsored by:</span>
                    <object type="image/svg+xml" data={kinoLogo} className={classes.sponsorIcon + " sponsorIcon"} aria-label="Kino" />
                    <object type="image/svg+xml" data={kinetxLogo} className={classes.sponsorIcon +  " sponsorIcon"} aria-label="Kinetx" />
                  </Grid>
                  
                  <Grid xs={5} className={classes.logoSection2 + " logoSection2 mobileGridItem logoItem"}>
                  {/* <span className={classes.sponsorMessage2 + " sponsorMessage2 supportMessage"}>In support of:</span> */}
                    <object type="image/svg+xml" data={bringThemHomeNowLogo} className={classes.sponsorIcon2 +  " sponsorIcon2"} aria-label="Bring Them Home Now" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </div>

      </div>
      <EventSignupModal open={modalOpen} setOpen={setModalOpen} />
    </div>
  );
};
export default AIWorkshop;